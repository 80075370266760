import React from "react"

const TypographySection = () => (

    <section id="Typography" className="guide wrapper">
    <div className="padded">
      <h1> Typography</h1>
      <p className="row">Both, this website and the app, use the system fonts <b>Helvetica</b> and <b>Arial</b>.</p>
      <p className="row">The typography automatically alternates between grey and beige to grant a high contrast that is easy to read even under bad-light circumstances. Note that the font is <b>not black</b> as the background isn't beige.</p>
      <p className="row">The style currently uses 4 different heading sizes. H1 being the main size, should be used only once per page. H2 is a subheading that can be used as often as necessary. H3 is a subset for H2. H4 is called "SEO Heading", since its size is the same as the text-body's size. The purose of this heading is making a semantically relevant markup and helping search engine crawlers identify keywords.</p>
      <div className="row wrapper spaced border">
        <div className="block25 card">
          <h1>Main Heading</h1>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet <a href="#0">link</a> .</p>
        </div>
        <div className="block25 card">
          <h2>Subheading</h2>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet <a href="#0">link</a> .</p>
        </div>
        <div className="block25 card">
          <h3>Important Heading</h3>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet <a href="#0">link</a> .</p>
        </div>
        <div className="block25 card">
          <h4>SEO Heading</h4>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet <a href="#0">link</a> .</p>
        </div>
      </div>
      <div className="row wrapper spaced border">

        <div className="block25 card bg-dark-gray font-beige-o">
          <h1>Main Heading</h1>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet <a href="#0">link</a> .</p>
        </div>
        <div className="block25 card bg-dark-gray font-beige-o">
          <h2>Subheading</h2>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet <a href="#0">link</a> .</p>
        </div>
        <div className="block25 card bg-dark-gray font-beige-o">
          <h3>Important Heading</h3>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet <a href="#0">link</a> .</p>
        </div>
        <div className="block25 card bg-dark-gray font-beige-o">
          <h4>SEO Heading</h4>
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet <a href="#0">link</a> .</p>
        </div>
      </div>
    </div>
  </section>
)

export default TypographySection